<script setup lang='ts'>
const props = withDefaults(defineProps<{
  keyList: string[]
  active: number
  activeClass?: string
  type?: 'click' | 'hover'
}>(), {
  keyList: () => [],
  active: 0,
  activeClass: 'bg-blue-5 text-white',
  type: 'hover',
})

const emits = defineEmits(['update:active'])

function changeItem(type = 'click', index: number) {
  if (props.type === type && index !== props.active)
    emits('update:active', index)
}
</script>

<template>
  <template v-for="(v, i) in keyList" :key="i">
    <span
      class="text-3.5 status" :class="[i === active ? activeClass : '']"
      @click="changeItem('click', i)" @mouseover="changeItem('hover', i)"
    >
      {{ v }}
    </span>
    <n-divider v-if="i < keyList.length - 1" vertical />
  </template>
</template>

<style lang='scss' scoped>
.status {
  --uno: py-0.25 px-1.5 rounded-md cursor-pointer;
  &.active {
    background: var(--bg);
    color: var(--color);
  }
}
.divider {
  --uno: h-0.5em m-0.2em;
}
</style>
